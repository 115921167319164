<template>
    <div class="html-default">
        <table border="1" class="mytable mytable-head">
            <thead>
                <tr>
                    <td class="px-3" width="10%" style="text-align: center;"><label>Purchase Order Number</label></td>
                    <td class="px-3" width="5%" style="text-align: center;"><label>Status</label></td>
                    <td class="px-3" width="10%" style="text-align: center;"><label>Kode Request</label></td>
                    <td class="px-3" width="10%" style="text-align: center;"><label>Tanggal Purchase Order</label></td>
                    <td class="px-3" width="5%" style="text-align: center;"><label>Pajak PPN %</label></td>
                    <td class="px-3" width="10%" style="text-align: center;"><label>Supplier</label></td>
                    <td class="px-3" width="10%" style="text-align: center;"><label>Kurs</label></td>
                    
                    <td class="px-3" width="10%" style="text-align: center;"><label>Alamat</label></td>
                    <td class="px-3" width="10%" style="text-align: center;"><label>DP</label></td>
                    <td class="px-3" width="10%" style="text-align: center;"><label>Kode Produk</label></td>
                    <td class="px-3" width="10%" style="text-align: center;"><label>Nama Item</label></td>
                    <td class="px-3" width="10%" style="text-align: center;"><label>Keterangan</label></td>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, index) in items" :key="index">
                    <td class="px-2"><label>{{ item['purchase_order_number'] }}</label></td>
                    <td class="px-2"><label>{{ item['status'] }}</label></td>
                    <td class="px-2"><label>{{ item['request_number'] }}</label></td>
                    <td class="px-2" >
                        <label>
                            {{item['purchase_order_date'] == "" || item['purchase_order_date'] == null ? '-' : 
                            new Date(item['purchase_order_date']).toLocaleDateString('es-CL') }}
                        </label>
                    </td>
                    <td class="px-2"><label>{{ item['ppn_tax_percentage'] }}</label></td>
                    <td class="px-2"><label>{{ item['supplier_name'] }}</label></td>
                    <td class="px-2"><label>{{ item['currency_name'] }}</label></td>
                    
                    <td class="px-2"><label>{{ item['address'] }}</label></td>
                    <td class="px-2"><label>{{ item['down_payment'] }}</label></td>
                    <td class="px-2"><label>{{ item['product_code'] }}</label></td>
                    <td class="px-2"><label>{{ item['item_name'] }}</label></td>
                    <td class="px-2"><label>{{ item['notes'] }}</label></td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf';
import moment from 'moment';
import { response } from '../../infrastructure/constant/response.js';
import PurchasingOrderReportService from '../../Areas/Reporting/PurchasingOrderReport/Script/PurchasingOrderReportService';

export default {
    name: 'PurchasingOrderReportHtml',
    data(){
        return{
            StartDate: this.$route.params.startdate,
            EndDate: this.$route.params.enddate,
            items: [],
        }
    },
    async mounted() {
        const variables = {
            startDate: this.StartDate,
            endDate: this.EndDate
        }
        var data = await PurchasingOrderReportService.getPurchasingOrderReport(variables);
        this.items = data.detail;
    }
}
</script>

<style scoped>
.col-sm-dot, .col-sm-label, .col-sm-content, .col-sm-half {
    position: relative;
    min-height: 1px;
    padding-right: 10px;
    padding-left: 10px;
}
@media (min-width: 768px) {
    .col-sm-label, .col-sm-content, .col-sm-half {
        float: left;
    }
    .col-sm-label {
        width: 30%;
    }
    .col-sm-dot {
        width: 5%;
    }
    .col-sm-content {
        width: 60%;
    }
    .col-sm-half{
        width: 33%;
    }
    .input-number{
        text-align: right;
    }
}

.mytable {
    border-collapse: collapse;
    width: 100%;
    background-color: white;
}
.mytable-head {
    border: 1px solid white;
    margin-bottom: 0;
    padding-bottom: 0;
}
.mytable-head td {
    border: 1px solid black;
}
.mytable-body {
    border: 1px solid black;
    border-top: 0;
    margin-top: 0;
    padding-top: 0;
    margin-bottom: 0;
    padding-bottom: 0;
}
.mytable-body td {
    border: 1px solid black;
    border-top: 0;
}
.mytable-footer {
    border: 1px solid black;
    border-top: 0;
    margin-top: 0;
    padding-top: 0;
}
.mytable-footer td {
    border: 1px solid black;
    border-top: 0;
}
</style>